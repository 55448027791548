import react from 'react'; // eslint-disable-line
import { graphql, useStaticQuery } from 'gatsby';
import { FaqItem } from '../gatsby/types/faq-item';

export type FaqsData = {
  faqs: {
    edges: {
      node: FaqItem;
    }[]
  }
}

export const useFaqs = (
): FaqItem[] => {
  const { faqs } = useStaticQuery<FaqsData>(graphql`
    query {
      faqs: allStrapiFaQs {
        edges {
          node {
            id
            title
            description
            category
            pageUrl
            featured
          }
        }
      }
    }
  `);

  return faqs.edges.map(({ node }) => node);
};
