import SmartLink from 'components/smart-link';
import {
  black,
  paleBlue,
  g100,
} from '@shipae/components-sandbox/component/colors';
import styled from 'styled-components';
import { fonts, media } from 'src/views/theme';
import {
  ReactComponent as ArrowRight,
} from '@shipae/components-sandbox/component/static-icons/arrow-right.svg';

export const Section = styled.section`
  position: relative;
  padding: 9rem calc((100% - var(--container-width)) / 2);
  overflow: hidden;
`;

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const flexGutter = 3;

export const Company = styled.div`
  border: 2px solid ${ paleBlue() };
  border-radius: 8px;
  padding: 0;

  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: calc((100% - (3 * ${ flexGutter }rem)) / 4);

  ${ media.max(1175) } {
    flex-basis: calc((100% - (2 * ${ flexGutter }rem)) / 3);
  }

  ${ media.max(750) } {
    flex-basis: calc((100% - (2 * ${ flexGutter }rem)) / 2);
  }

  ${ media.sm } {
    flex-basis: 100%;
  }

  margin-bottom: 5rem;

  display: flex;
  flex-direction: column;

  overflow: hidden;
`;

export const Dummy = styled(Company)`
  border: none;
  margin: 0;
`;

export const TopPart = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 28.5rem;
`;

export const Logo = styled.img`
  display: block;
  margin: 2rem auto 0;
`;

export const QList = styled.ul`
  margin-top: 2rem;
  list-style: none;
  margin-left: 0;
  padding: 0 1.5rem;
`;

export const QListItem = styled.li`
  padding-left: 1em;
  text-indent: -2.5rem;
  margin: 5px 0;

  ${ media.sm } {
    margin: 15px 0;
  }

  &:before {
    content: "•";
    padding-right: 5px;
  }
`;

export const QLink = styled(SmartLink)`
  ${ fonts.bodys200 };

  ${ media.sm } {
    ${ fonts.bodyl200 };
  }

  color: ${ black() };

  &:hover {
    text-decoration: none;
  }
`;

export const ViewAllLink = styled(SmartLink)`
  display: block;
  ${ fonts.bodys300 };

  ${ media.sm } {
    ${ fonts.bodyl300 };
  }

  color: ${ black() };
  margin: 2.5rem 1.5rem 2rem;

  &:hover {
    text-decoration: none;
  }
`;

export const StyledArrowRight = styled(ArrowRight)`
  width: 2rem;
  transform: translateY(25%);
  margin-left: 0.5rem;

  html[dir='rtl'] & {
    transform: rotate(180deg);
  }
`;

export const Contacts = styled.ul`
  list-style: none;
  background-color: ${ g100() };

  padding: 2.7rem 1.5rem;

  flex-grow: 1;
`;

export const ContactWrapper = styled.li``;

export const ContactRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 1.5rem 0;
`;

export const Country = styled.div`
  ${ fonts.bodys200 };

  ${ media.sm } {
    ${ fonts.bodyl200 };
  }

  display: flex;
  align-items: center;
`;

export const Flag = styled.img`
  margin-right: 1rem;
`;

export const ContactLink = styled(SmartLink)`
  ${ fonts.bodys300 };

  ${ media.sm } {
    ${ fonts.bodyl300 };
  }

  color: ${ black() };
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Address = styled.p`
  ${ fonts.bodys200 };

  ${ media.sm } {
    ${ fonts.bodyl200 };
  }
`;
