import React from 'react';
import {
  FaqsContactDetails as IFaqsContactDetails,
} from 'src/infrastructure/gatsby/types/faqs-contact-details';
import { useTranslation } from 'src/views/misc/localization';
import { FaqItem } from 'infrastructure/gatsby/types/faq-item';
import { useFaqs } from 'src/infrastructure/hooks/use-faqs';
import {
  useFaqsTranslations,
} from 'src/infrastructure/hooks/use-faqs-translations';
import { Heading } from '../shared/heading';
import {
  Address,
  Company,
  ContactLink,
  ContactRow,
  Contacts,
  ContactWrapper,
  Country,
  Dummy,
  Flag,
  Flex,
  Logo,
  QLink,
  QList,
  QListItem,
  Section,
  StyledArrowRight,
  TopPart,
  ViewAllLink,
} from './styled';

type Props = Omit<IFaqsContactDetails, 'type'>;

const FaqsContactDetails: React.FC<Props> = ({
  sectionId,
  title,
  companies,
}) => {
  const { locale } = useTranslation();
  const faqsEnglish = useFaqs();
  const faqsArabic = useFaqsTranslations();
  const allFaqs = locale === 'ar'
    ? faqsArabic.filter((faq: FaqItem) => !!faq.featured)
    : faqsEnglish.filter((faq: FaqItem) => !!faq.featured);

  return (
    <Section id={sectionId}>
      <Heading>{title}</Heading>
      <Flex>
        {companies.map((company) => {
          const { faqCategory } = company;
          const faqs = allFaqs.filter(
            // why we filter by category starting with faqCategory:
            // to avoid having categokry & subCategory both were merged into
            // just categkory:
            // - ecommerce
            // - deliveryPersonal
            // - deliveryBusiness
            // - mall
            // - freight
            // so in case of delivery instead having delivery category and
            // personal or business as sub category we have just two categoris
            //
            // But there is use case (help center faqs and contacts)
            // when we need content editor to be able to specify category
            // independently from subCategory and to achieve this a convention
            // was made:
            // in help center flow we use different list of categoris:
            // - ecommerce
            // - delivery
            // - mall
            // - freight
            // i.e. list of categories itself without subCategory suffix
            (faq: FaqItem) => faq.category.startsWith(faqCategory),
          );

          return (
            <Company key={company.id}>
              <TopPart>
                <Logo
                  src={company.logo.localFile.publicURL}
                  alt={company.logo.alternativeText || 'Shipa logo'}
                />
                <QList>
                  {faqs?.map((faq) => {
                    const isExternal = /^http/.test(faq.pageUrl);
                    const href = isExternal
                      ? faq.pageUrl
                      : `${ faq.pageUrl }/?faqid=${
                        faq.id
                      }&faqcategory=${ faq.category }`;

                    return (
                      <QListItem key={faq.id}>
                        <QLink href={href}>
                          {faq.title}
                        </QLink>
                      </QListItem>
                    );
                  })}
                </QList>
                <ViewAllLink
                  href={company.viewAllFaqs.url}
                >
                  {company.viewAllFaqs.label}
                  <StyledArrowRight />
                </ViewAllLink>
              </TopPart>
              <Contacts>
                {company.contacts.map((contact) => (
                  <ContactWrapper key={contact.id}>
                    <ContactRow>
                      <Country>
                        <Flag
                          src={contact.icon?.localFile.publicURL}
                          alt={contact.icon?.alternativeText || 'Flag'}
                        />
                        {contact.name}
                      </Country>
                      <ContactLink
                        href={`tel:${ contact?.numberLink?.url || '' }`}
                      >
                        {contact?.numberLink?.label}
                      </ContactLink>
                    </ContactRow>
                    <Address>{contact.contactDescription}</Address>
                  </ContactWrapper>
                ))}
              </Contacts>
            </Company>
          );
        })}
        {/* We need the below to prevent middle space */}
        {/* Since we're using flex space between */}
        <Dummy />
        <Dummy />
        <Dummy />
      </Flex>
    </Section>
  );
};

export default FaqsContactDetails;
